export const ONBOARDED_TENANT_DETAILS_REQUEST =
  "ONBOARDED_TENANT_DETAILS_REQUEST";
export const ONBOARDED_TENANT_DETAILS_SUCCESS =
  "ONBOARDED_TENANT_DETAILS_SUCCESS";
export const ONBOARDED_TENANT_DETAILS_FAIL = "ONBOARDED_TENANT_DETAILS_FAIL";

export const ONBOARDED_TENANT_PAYMENT_REQUEST =
  "ONBOARDED_TENANT_PAYMENT_REQUEST";
export const ONBOARDED_TENANT_PAYMENT_SUCCESS =
  "ONBOARDED_TENANT_PAYMENT_SUCCESS";
export const ONBOARDED_TENANT_PAYMENT_FAIL = "ONBOARDED_TENANT_PAYMENT_FAIL";

export const ONBOARDED_TENANT_PAYMENT_DETAILS_REQUEST =
  "ONBOARDED_TENANT_PAYMENT_DETAILS_REQUEST";
export const ONBOARDED_TENANT_PAYMENT_DETAILS_SUCCESS =
  "ONBOARDED_TENANT_PAYMENT_DETAILS_SUCCESS";
export const ONBOARDED_TENANT_PAYMENT_DETAILS_FAIL =
  "ONBOARDED_TENANT_PAYMENT_DETAILS_FAIL";
