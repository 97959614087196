import {
	SUBMIT_TENANT_ENQUIRY_REQUEST,
	SUBMIT_TENANT_ENQUIRY_SUCCESS,
	SUBMIT_TENANT_ENQUIRY_FAIL,
	SUBMIT_PROPERTY_FORM_REQUEST,
	SUBMIT_PROPERTY_FORM_SUCCESS,
	SUBMIT_PROPERTY_FORM_FAIL,
	SUBMIT_SERVICE_REQUEST,
	SUBMIT_SERVICE_SUCCESS,
	SUBMIT_SERVICE_FAIL,
	SUBMIT_CONTACTUS_REQUEST,
	SUBMIT_CONTACTUS_SUCCESS,
	SUBMIT_CONTACTUS_FAIL,
} from '../constants/enquiryConstant'

const initialStateTenantEnquiry = {
	loading: false,
	status: null,
	success: null,
	error: null,
}

const initialStateOwnerEnquiry = {
	loading: false,
	status: null,
	success: null,
	error: null,
}

const initialStateServiceEnquiry = {
	loading: false,
	status: null,
	success: null,
	error: null,
}

const initialStateContactUsEnquiry = {
	loading: false,
	status: null,
	success: null,
	error: null,
}

export const tenantEnquiryReducer = (
	state = initialStateTenantEnquiry,
	action
) => {
	switch (action.type) {
		case SUBMIT_TENANT_ENQUIRY_REQUEST:
			return {...state, loading: true}
		case SUBMIT_TENANT_ENQUIRY_SUCCESS:
			return {
				...state,
				loading: false,
				status: action.payload.status,
				success: action.payload.success,
			}
		case SUBMIT_TENANT_ENQUIRY_FAIL:
			return {
				...state,
				loading: false,
				status: action.payload.status,
				error: action.payload.error,
			}

		default:
			return state
	}
}

export const submitPropertyReducer = (
	state = initialStateOwnerEnquiry,
	action
) => {
	switch (action.type) {
		case SUBMIT_SERVICE_REQUEST:
			return {...state, loading: true}
		case SUBMIT_PROPERTY_FORM_SUCCESS:
			return {
				...state,
				loading: false,
				status: action.payload.status,
				success: action.payload.success,
			}
		case SUBMIT_PROPERTY_FORM_FAIL:
			return {
				...state,
				loading: false,
				status: action.payload.status,
				error: action.payload.error,
			}

		default:
			return state
	}
}

export const submitServiceReducer = (
	state = initialStateServiceEnquiry,
	action
) => {
	switch (action.type) {
		case SUBMIT_PROPERTY_FORM_REQUEST:
			return {...state, loading: true}
		case SUBMIT_SERVICE_SUCCESS:
			return {
				...state,
				loading: false,
				status: action.payload.status,
				success: action.payload.success,
			}
		case SUBMIT_SERVICE_FAIL:
			return {
				...state,
				loading: false,
				status: action.payload.status,
				error: action.payload.error,
			}

		default:
			return state
	}
}

export const submitContactUsReducer = (
	state = initialStateContactUsEnquiry,
	action
) => {
	switch (action.type) {
		case SUBMIT_CONTACTUS_REQUEST:
			return {...state, loading: true}
		case SUBMIT_CONTACTUS_SUCCESS:
			return {
				...state,
				loading: false,
				status: action.payload.status,
				success: action.payload.success,
			}
		case SUBMIT_CONTACTUS_FAIL:
			return {
				...state,
				loading: false,
				status: action.payload.status,
				error: action.payload.error,
			}

		default:
			return state
	}
}
