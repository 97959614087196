import {
  ONBOARDED_TENANT_DETAILS_REQUEST,
  ONBOARDED_TENANT_DETAILS_SUCCESS,
  ONBOARDED_TENANT_DETAILS_FAIL,
  ONBOARDED_TENANT_PAYMENT_REQUEST,
  ONBOARDED_TENANT_PAYMENT_SUCCESS,
  ONBOARDED_TENANT_PAYMENT_FAIL,
  ONBOARDED_TENANT_PAYMENT_DETAILS_REQUEST,
  ONBOARDED_TENANT_PAYMENT_DETAILS_SUCCESS,
  ONBOARDED_TENANT_PAYMENT_DETAILS_FAIL,
} from "../constants/onboardedTenantConstants";

const initialStateOnboardedTenant = {
  loading: false,
  tenant: {},
  error: null,
};

const initialStatePayments = {
  loading: false,
  payments: [],
  error: null,
};

const initialStatePayment = {
  loading: false,
  payment: {},
  error: null,
};

export const onboardedTenantDetailsReducer = (
  state = initialStateOnboardedTenant,
  action
) => {
  switch (action.type) {
    case ONBOARDED_TENANT_DETAILS_REQUEST:
      return { ...state, loading: true, tenant: {} };
    case ONBOARDED_TENANT_DETAILS_SUCCESS:
      return { ...state, loading: false, tenant: action.payload };
    case ONBOARDED_TENANT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        tenant: {},
      };

    default:
      return state;
  }
};

export const onboardedTenantPaymentsReducer = (
  state = initialStatePayments,
  action
) => {
  switch (action.type) {
    case ONBOARDED_TENANT_PAYMENT_REQUEST:
      return { ...state, loading: true, payments: [] };
    case ONBOARDED_TENANT_PAYMENT_SUCCESS:
      return { ...state, loading: false, payments: action.payload };
    case ONBOARDED_TENANT_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        payments: [],
      };

    default:
      return state;
  }
};

export const onboardedTenantPaymentDetailsReducer = (
  state = initialStatePayment,
  action
) => {
  switch (action.type) {
    case ONBOARDED_TENANT_PAYMENT_DETAILS_REQUEST:
      return { ...state, loading: true, payment: {} };
    case ONBOARDED_TENANT_PAYMENT_DETAILS_SUCCESS:
      return { ...state, loading: false, payment: action.payload };
    case ONBOARDED_TENANT_PAYMENT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        payment: {},
      };

    default:
      return state;
  }
};
