import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import { paymentVerificationCFAPI } from "../../config/api";

const PaymentStatus = () => {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentSuccessfull, setIsPaymentSuccessfull] = useState(false);

  let searchStringParams = null;
  let order_id = null;

  if (location.search) {
    searchStringParams = queryString.parse(location.search);
    order_id = searchStringParams.order_id.replace("{", "").replace("}", "");
  }

  useEffect(() => {
    const checkPaymentVerification = async (orderID) => {
      //   const config = {
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   };

      //make a call to api to check status
      const verificationStatus = await axios.post(
        paymentVerificationCFAPI,

        {
          orderId: orderID,
        }
      );

      console.log("verification ", verificationStatus);

      // THIS NEEDS TO CHANGE WITH POSITIVE AFFIRMATION
      if (verificationStatus.data.status === "PAID") {
        console.log("INSIDE VERIFICATION STATUS");
        setIsLoading(false);
        setIsPaymentSuccessfull(true);
      } else {
        setIsLoading(false);
        setIsPaymentSuccessfull(true);
      }
    };

    let searchStringParams = null;
    let order_id = null;
    let order_token = null;

    if (location.search) {
      searchStringParams = queryString.parse(location.search);
      order_id = searchStringParams.order_id.replace("{", "").replace("}", "");
      order_token = searchStringParams.order_token
        .replace("{", "")
        .replace("}", "");
    }

    if (order_id && order_token) {
      //assign setIsPaymentSuccessfull to true if success
      checkPaymentVerification(order_id);
    }
  }, [location.search]);

  return (
    <Container className="min-vh-100" fluid>
      <Row>
        <Col lg={4}></Col>
        <Col lg={4} className="mt-4 mb-4 p-3">
          <Row>
            <Col className="mt-5 rounded bg-white shadow-none p-5 bg-light rounded border border-dark">
              {isLoading ? (
                <div className="text-center">
                  <h3 className="pt-4 pb-2 text-capitalize text-center">
                    VERIFYING YOUR TRANSACTION, PLEASE WAIT!
                  </h3>
                  <Spinner
                    animation="grow"
                    variant="warning"
                    style={{ width: "4rem", height: "4rem" }}
                  />
                </div>
              ) : isPaymentSuccessfull ? (
                <div className="text-center">
                  <h3 className="pt-4 pb-2 text-capitalize text-center">
                    Your payment is succesfull
                  </h3>
                  <div className="pb-4 text-center ">
                    <img src="/payment-success.png" alt="payment successful" />
                  </div>
                  <div>
                    <h6 className="mb-2">Your order id is: {order_id}</h6>

                    <Button className="mt-2" href="/" variant="primary">
                      Go Back to Dashboard
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <h3 className="pt-4 pb-2 text-capitalize text-center">
                    YOUR PAYMENT IS NOT COMPLETE!
                  </h3>
                  <div className="pb-4 text-center ">
                    <img src="/payment-failure.png" alt="payment failure" />
                  </div>
                  <div>
                    <Button className="mt-2" href="/" variant="primary">
                      Go Back to Dashboard
                    </Button>
                  </div>
                </div>
              )}

              <br />
            </Col>
          </Row>
        </Col>
        <Col lg={4}></Col>
      </Row>
    </Container>
  );
};

export default PaymentStatus;
