import React from 'react';
import { Fragment, useState } from 'react';
import Sidebar from '../../components/Sidebar/';
import { Col, Container, Row } from 'react-bootstrap';
import HeaderBar from './HeaderBar';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

const SideBarContainer = (props) => {
	const location = useLocation();

	const isReceiptPage = () => {
		if (location.pathname.includes('tenant-payment-details')) {
			return true;
		}
		return false;
	};
	console.log(isReceiptPage());
	const PageComponent = props.component;
	const [isSidePanelOpen, setSidePanelOpen] = useState(
		isMobile ? false : isReceiptPage() ? false : true
	);

	// tenant-payment-details

	return (
		<Fragment>
			<Container fluid>
				<Row>
					<Col
						sm={isSidePanelOpen ? 2 : 0}
						md={isSidePanelOpen ? 2 : 0}
						lg={isSidePanelOpen ? 2 : 0}
						id={isSidePanelOpen ? 'sidebar-wrapper' : ''}
					>
						{isSidePanelOpen ? (
							<Sidebar
								setSidePanelOpen={setSidePanelOpen}
								isSidePanelOpen={isSidePanelOpen}
							/>
						) : null}
					</Col>
					<Col
						sm={isSidePanelOpen ? 10 : 12}
						md={isSidePanelOpen ? 10 : 12}
						lg={isSidePanelOpen ? 10 : 12}
					>
						<HeaderBar
							setSidePanelOpen={setSidePanelOpen}
							isSidePanelOpen={isSidePanelOpen}
						/>

						<PageComponent {...props} />
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default SideBarContainer;
