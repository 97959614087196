import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Nav } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { logout } from '../../actions/authActions';
import './Sidebar.css';

const Side = ({ setSidePanelOpen, isSidePanelOpen }) => {
	const dispatch = useDispatch();
	const onLogoutHandler = () => {
		dispatch(logout());
	};
	const onHamburgerClickHandler = () => {
		setSidePanelOpen(!isSidePanelOpen);
	};

	return (
		<Col fluid className='minHeight100' style={{ backgroundColor: '#1D3557' }}>
			<Nav
				className='col-md-12 pl-2 backgroundColorBlue flex-column'
				activeKey='/'
			>
				<div className='text-right mt-4 mr-2'>
					<img
						onClick={onHamburgerClickHandler}
						src='/hamburger.png'
						alt='open panel'
					/>
				</div>
				<hr />
				<Nav.Link href='/home'>
					<h4 className='colorWhite text-left align-self-center'>
						TENANT PORTAL
					</h4>
				</Nav.Link>
				<Nav.Item>
					<Nav.Link className='colorWhite' href='/'>
						DASHBOARD
					</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link className='colorWhite' href='/report-issue'>
						REPORT AN ISSUE
					</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link className='colorWhite' href='/reset-password'>
						CHANGE PASSWORD
					</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link onClick={onLogoutHandler} className='colorWhite' href='# '>
						LOGOUT
					</Nav.Link>
				</Nav.Item>

				<hr />
			</Nav>
		</Col>
	);
};
const Sidebar = withRouter(Side);
export default Sidebar;
