import React, { Fragment, Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

import SideBarContainer from "../container/SidebarContainer";
import PaymentStatus from "../pages/PaymentStatus/index";

const Homepage = lazy(() => import("../pages/Home"));

// const ContactUs = lazy(() => import("../pages/ContactUs"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const ReportIssue = lazy(() => import("../pages/ReportIssue"));
const ReportReceived = lazy(() =>
  import("../pages/ReportIssue/components/ReportReceived")
);
const ResetSuccess = lazy(() =>
  import("../pages/ResetPassword/components/ResetSuccess")
);

const Login = lazy(() => import("../pages/Signin"));

const TenantPaymentDetails = lazy(() =>
  import("../pages/Home/components/PreviousPayments/ViewDetails")
);

const Routes = () => {
  const RoutesList = [
    {
      title: "Best Property Management Services In Hyderabad | Owner & Tenant",
      description:
        "We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/",
      keywords: "Property Management Services In Hyderabad",
      path: "/",
      component: Homepage,
      isPrivate: false,
    },
    {
      title: "Best Property Management Services In Hyderabad | Owner & Tenant",
      description:
        "We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/",
      keywords: "Property Management Services In Hyderabad",
      path: "/login",
      component: Login,
      isPrivate: false,
    },
    {
      title: "Best Property Management Services In Hyderabad | Owner & Tenant",
      description:
        "We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/",
      keywords: "Property Management Services In Hyderabad",
      path: "/reset-password",
      component: ResetPassword,
      isPrivate: false,
    },
    {
      title: "Best Property Management Services In Hyderabad | Owner & Tenant",
      description:
        "We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/",
      keywords: "Property Management Services In Hyderabad",
      path: "/report-issue",
      component: ReportIssue,
      isPrivate: false,
    },
    {
      title: "Best Property Management Services In Hyderabad | Owner & Tenant",
      description:
        "We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/",
      keywords: "Property Management Services In Hyderabad",
      path: "/report-received",
      component: ReportReceived,
      isPrivate: false,
    },
    {
      title: "Best Property Management Services In Hyderabad | Owner & Tenant",
      description:
        "We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/",
      keywords: "Property Management Services In Hyderabad",
      path: "/reset-success",
      component: ResetSuccess,
      isPrivate: false,
    },
    {
      title: "Best Property Management Services In Hyderabad | Owner & Tenant",
      description:
        "We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/",
      keywords: "Property Management Services In Hyderabad",
      path: "/payment-status-return",
      component: PaymentStatus,
      isPrivate: false,
    },
    {
      title: "Best Property Management Services In Hyderabad | Owner & Tenant",
      description:
        "We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/",
      keywords: "Property Management Services In Hyderabad",
      path: "/tenant-payment-details/:id",
      component: TenantPaymentDetails,
      isPrivate: false,
    },
  ];
  return (
    <Fragment>
      <Suspense
        fallback={
          <div className="loaderImg">
            <p>Loading...</p>
          </div>
        }
      >
        <Switch onUpdate={() => window.scrollTo(0, 0)}>
          {RoutesList.map((item, routeIdx) => {
            return (
              <Route
                key={routeIdx}
                exact
                path={item.path}
                render={(props) => (
                  <SideBarContainer
                    {...props}
                    props={props}
                    component={item.component}
                    title={item.title}
                    description={item.description}
                    keywords={item.keywords}
                  />
                )}
              />
            );
          })}
        </Switch>
      </Suspense>
    </Fragment>
  );
};

export default Routes;
