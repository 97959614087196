import React from 'react';
import { useDispatch } from 'react-redux';
import { Container, Dropdown, Button } from 'react-bootstrap';
import { logout } from '../../actions/authActions';
import Navbar from 'react-bootstrap/Navbar';
import { isMobile } from 'react-device-detect';

const HeaderBar = ({ setSidePanelOpen, isSidePanelOpen }) => {
	const dispatch = useDispatch();
	const onLogoutHandler = () => {
		dispatch(logout());
	};
	const onHamburgerClickHandler = () => {
		setSidePanelOpen(!isSidePanelOpen);
	};
	return (
		<Navbar>
			<Container>
				<Navbar.Brand href='#home'>
					<div>
						<h3>
							{!isSidePanelOpen ? (
								<img
									onClick={onHamburgerClickHandler}
									src='/hamburger.png'
									alt='SVG open panel'
								/>
							) : null}
							{'    '}
							{isMobile ? null : 'DASHBOARD'}
						</h3>
					</div>
				</Navbar.Brand>
				<Navbar.Toggle />
				<Navbar.Collapse className='justify-content-end'>
					<Navbar.Text>
						<Dropdown>
							<Dropdown.Toggle
								className='p-2 m-2'
								style={{
									backgroundColor: 'white',
									color: 'black',
									border: 'none',
									borderRadius: '0.4rem',
									outline: 'none',
								}}
								id='dropdown-basic'
							>
								{localStorage.getItem('User')}
							</Dropdown.Toggle>

							<Dropdown.Menu
								style={{
									borderRadius: '0.4rem',
									left: '-4rem',
								}}
							>
								<Dropdown.Item
									href='/report-issue'
									style={{ backgroundColor: 'white', color: 'black' }}
								>
									Report an issue
								</Dropdown.Item>

								<Dropdown.Item
									href='/reset-password'
									style={{ backgroundColor: 'white', color: 'black' }}
								>
									Reset password
								</Dropdown.Item>

								<Dropdown.Item
									href=''
									style={{ backgroundColor: 'white', color: 'black' }}
								>
									<Button
										onClick={onLogoutHandler}
										className=' pr-4 pl-4 pt-1 pb-1 border border-white'
										style={{
											backgroundColor: '#ebdde1',
											borderRadius: '10px',
											color: 'red',
										}}
									>
										Logout
									</Button>
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Navbar.Text>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

export default HeaderBar;
