import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  authReducers,
  resetPasswordReducer,
  raiseQueryReducer,
} from "./reducers/authReducers";
import alertsReducer from "./reducers/alertReducers";

import { submitContactUsReducer } from "./reducers/enquiryReducer";

import {
  onboardedTenantDetailsReducer,
  onboardedTenantPaymentsReducer,
  onboardedTenantPaymentDetailsReducer,
} from "./reducers/onboardedTenantReducers";

const reducer = combineReducers({
  auth: authReducers,
  resetPassword: resetPasswordReducer,
  raiseQuery: raiseQueryReducer,
  alerts: alertsReducer,
  contactUsEnquiryForm: submitContactUsReducer,
  getOnboardedTenantDetails: onboardedTenantDetailsReducer,
  getOnboardedTenantPayments: onboardedTenantPaymentsReducer,
  getOnboardedTenantRentPaymentDetails: onboardedTenantPaymentDetailsReducer,
});

const middleware = [thunk];

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
