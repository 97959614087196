import axios from "axios";
import { APIlogin } from "../config/api";

import {
  USER_LOAD_REQUEST,
  USER_LOAD_SUCCESS,
  USER_LOAD_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RAISE_QUERY_REQUEST,
  RAISE_QUERY_SUCCESS,
  RAISE_QUERY_FAILURE,
} from "../constants/authConstants";
import { setAlert } from "./alertActions";

import { resetPasswordAPI, raiseQueryAPI } from "../config/api";

import { localStorageToken, localStorageUser } from "../config/localStorage";

//LOGIN USER
export const login = (email, password) => async (dispatch) => {
  dispatch({
    type: LOGIN_REQUEST,
  });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      APIlogin,
      {
        email,
        password,
      },
      config
    );

    console.log("RESULT FROM LOGIN API", res);
    localStorage.setItem("User", res.data.user.name.toUpperCase());
    localStorage.setItem("userId", res.data.user._id);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert("Login Failed", "danger"));
    dispatch({
      type: LOGIN_FAILURE,
    });
  }
};

//LOGOUT
export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });

  window.location.replace("/");
};

// //Load User
export const loadAccess = (email) => async (dispatch) => {
  dispatch({
    type: USER_LOAD_REQUEST,
  });

  if (
    localStorage.getItem(localStorageToken) &&
    localStorage.getItem(localStorageUser)
  ) {
    //TOKEN is Present in local storage
    let res = {
      isAuthenticated: true,
      user: localStorage.getItem(localStorageUser),
    };
    dispatch({
      type: USER_LOAD_SUCCESS,
      payload: res,
    });
  } else {
    dispatch({
      type: USER_LOAD_FAILURE,
    });
  }
};

export const resetPasswordAction = (enquiryObject) => async (dispatch) => {
  let postBody = enquiryObject;
  console.log("postBody::::::", postBody);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.OATAuthTokenAdmin}`,
    },
  };
  console.log("config:::::", config);

  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    const result = await axios.post(resetPasswordAPI, postBody, config);

    console.log("SUBMIT reset password REQUEST", result);

    if (result.status === 200) {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: {
          status: true,
          success: "password reset successfully",
        },
      });
    } else {
      dispatch({
        type: RESET_PASSWORD_FAILURE,
        payload: {
          status: false,
          error:
            result.data.inputErrors.exists === true
              ? "User Record Already Exists"
              : "Please contact customer centre if issue persists",
        },
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: RESET_PASSWORD_FAILURE,
      payload: {
        status: false,
        error: "Incorrect password",
      },
    });
  }
};

export const raiseQueryAction = (enquiryObject) => async (dispatch) => {
  let postBody = enquiryObject;
  console.log("postBody::::::", postBody);

  try {
    dispatch({ type: RAISE_QUERY_REQUEST });
    const result = await axios.post(raiseQueryAPI, postBody);

    console.log("SUBMIT reset password REQUEST", result);

    if (result.status === 200) {
      dispatch({
        type: RAISE_QUERY_SUCCESS,
        payload: {
          status: true,
          success: "query submitted successfully",
        },
      });
    } else {
      dispatch({
        type: RAISE_QUERY_FAILURE,
        payload: {
          status: false,
          error:
            result.data.inputErrors.exists === true
              ? "User Record Already Exists"
              : "Please contact customer centre if issue persists",
        },
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: RESET_PASSWORD_FAILURE,
      payload: {
        status: false,
        error: "Incorrect data",
      },
    });
  }
};
