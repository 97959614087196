const API_URL = "https://zeus.ownerandtenant.com/v1.0";

export const getAllPropertiesAPI = `${API_URL}/api/adminportal/getallproperties`;
export const getPropertytDetailsAPI = `${API_URL}/api/adminportal/getproperty`;
export const submitSubscriptionEnquiryAPI = `${API_URL}/api/website/emailsubscription`;
export const tenantEnquiryAPI = `${API_URL}/api/website/tenantenquiry`;
export const submitPropertyFormAPI = `${API_URL}/api/website/ownerenquiry`;
export const serviceRequestAPI = `${API_URL}/api/website/servicerequest`;
export const contactUsAPI = `${API_URL}/api/website/contactus`;
export const getAllBlogsAPI = `${API_URL}/api/adminportal/getallblogs`;
export const getBlogDetailsAPI = `${API_URL}/api/adminportal/getblog`;

export const APIlogin = `${API_URL}/auth/user/login`;

export const getOnboardedTenantDetailsAPI = `${API_URL}/api/adminportal/get-onboarded-tenant-rent-details`;
export const getOnboardedTenantPaymentsAPI = `${API_URL}/api/adminportal/get-onboarded-tenant-payments`;
export const resetPasswordAPI = `${API_URL}/auth/user/changepassword`;
export const raiseQueryAPI = `${API_URL}/api/adminportal/raise-query`;

export const createCFOrderIDAPI = `${API_URL}/api/adminportal/generatepaymentslink`;
export const paymentVerificationCFAPI = `${API_URL}/api/adminportal/paymentstatus`;
export const tenantRentPaymentDetailsByIdAPI = `${API_URL}/api/adminportal/tenant-rent-receipt-details`;
