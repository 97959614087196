import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import LoginForm from "./components/LoginForm";

const Signin = () => {
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated } = auth;

  if (isAuthenticated) {
    return <Redirect to="/" />;
  } else {
    return (
      <Container>
        <Row>
          <Col lg={4}></Col>
          <Col lg={4} className="mt-3 p-4">
            <Row>
              <Col className="mt-4 rounded bg-white shadow-none p-4 bg-light rounded border border-dark text-center">
                <Row className="text-center">
                  <Col xs={12} md={2} lg={2} className="align-self-center">
                    {/* <img
											src='/android-chrome-192x192.png'
											alt='logo'
											style={{ height: '50%', width: '50%' }}
										/> */}
                  </Col>
                  <Col
                    xs={12}
                    md={8}
                    lg={8}
                    className="text-center align-self-center"
                  >
                    <img
                      src="/android-chrome-192x192.png"
                      alt="logo"
                      style={{ height: "40%", width: "40%" }}
                    />
                    <h2 className="text-center text-capitalize text-muted">
                      Owner And Tenant
                    </h2>
                    <h2 className="text-capitalize text-muted text-center">
                      Tenant Portal
                    </h2>
                  </Col>
                  <Col xs={12} md={2} lg={2}></Col>
                </Row>

                {/* <h4 className='pt-2 text-capitalize'>LogIn</h4>
								<div className='pb-4 '>Enter your email and password below</div> */}
                <LoginForm />
                <br />
              </Col>
            </Row>
          </Col>
          <Col lg={4}></Col>
        </Row>
      </Container>
    );
  }
};

export default Signin;
