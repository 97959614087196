import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { login } from "../../../actions/authActions";

const LoginForm = () => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    let isFormValid = true;

    if (password.length < 5) {
      isFormValid = false;
    }

    if (isFormValid) {
      dispatch(login(email, password));
    }
  };
  return (
    <Container>
      <Form onSubmit={onSubmitHandler}>
        <Form.Group controlId="studentEmail" style={{ textAlign: "left" }}>
          <Form.Label>Email</Form.Label>
          <Form.Control
            name="email"
            value={email}
            onChange={onChangeHandler}
            type="email"
            placeholder="Enter your email"
            className="inputEmail"
            required
          />
        </Form.Group>
        <br />

        <Form.Group controlId="studentPassword" style={{ textAlign: "left" }}>
          <Form.Label>Password</Form.Label>
          <Form.Control
            autoComplete={"false"}
            name="password"
            value={password}
            onChange={onChangeHandler}
            type="password"
            placeholder="Password"
            className="inputPassword"
            required
          />
        </Form.Group>
        <div className="text-center">
          <Button
            variant="success"
            type="submit"
            className="mt-3 rounded text-capitalize"
            style={{
              width: "70%",
              backgroundColor: "#2a454f",
            }}
          >
            Log In
          </Button>
        </div>
        <div className="pt-2 text-center">
          Don't receive your credentials?
          <Link
            to={{ pathname: "https://www.ownerandtenant.com/contact-us" }}
            style={{ color: "red" }}
            target="_blank"
          >
            Contact Us
          </Link>
        </div>
      </Form>
    </Container>
  );
};

export default LoginForm;
